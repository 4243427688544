<template>
  <div class="Awarp">
    <dashCard
      class="carbonBox4"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('carbonBox4_carbonEmissionKPI' , '碳排放KPI', getZEdata)}}</template>
      <template slot="aside">
        <!-- <a-date-picker :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)" v-model="searchObj.VALUE" @change="onChange" mode="year" :open="panelOpen"
                                @openChange="openChange" @panelChange="panelChange" format="YYYY" /> -->
        <!-- <a-select v-model="searchObj['QUARTER']" style="width: 120px" @change="handleChange">
                                <a-select-option v-for="(item,key) in appDictData['QUARTER'].subDicts" :key="key" :value="item.code">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select> -->
        <div class="card-content1">
          <div class="type-group">
            <span
              class="pick-type"
              v-for="(item, idx) in timetType"
              :key="idx"
              @click="changeType(item.code)"
            >
              <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
                item.name
              }}</font>
            </span>
          </div>
          <a-month-picker
            v-if="searchObj.TYPE == '按月'"
            :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
          />
          <a-date-picker
            v-if="searchObj.TYPE == '按年'"
            :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
            mode="year"
            :open="panelOpen"
            @openChange="openChange"
            @panelChange="panelChange"
            format="YYYY"
          />
          <div style="width: 120px" v-if="searchObj.TYPE == '按季'">
            <el-select
              v-model="value"
              :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)"
              @change="handleChange"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </template>

      <!-- <div class="card-content1">
                            <a-radio-group v-model="searchObj.TYPE" button-style="solid" @change="handleChange">
                                <a-radio-button value="碳排放">碳排放</a-radio-button>
                                <a-radio-button value="碳减排">碳减排</a-radio-button>
                            </a-radio-group>
                        </div> -->
      <div class="card-content2">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <carbonBox4
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "carbonBox4",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      panelOpen: false,
      timetType: [
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      panelOpen: false,
      searchObj: {
        TYPE: "按月", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      options: [
        {
          value: "1",
          label: "第一季度",
        },
        {
          value: "2",
          label: "第二季度",
        },
        {
          value: "3",
          label: "第三季度",
        },
        {
          value: "4",
          label: "第四季度",
        },
      ],
      quarter: false,
      value: "1",
      chartOption1: {},
      detailInfo: {},
      colorList: ["#3366FF", "#B4D0FF", "#F03040", "#51A34B"],
    };
  },
  computed: {
    currentSelectMenuInfo() {
      return this.$store.state.currentSelectMenuInfo;
    },
    appDictData() {
      return this.currentSelectMenuInfo.appDictData;
    },
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },

    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange() {
      this.getData();
    },
    // handleChange(value) {
    //     //console.log("handleChange", value, this.searchObj)
    // },
    handleChange(value) {
      if (!!value) this.value = value;
      this.getData();
    },
    getTimes(value) {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      let obj = {
        starttime: "",
        endtime: "",
      };
      if (value == "1") {
        obj.starttime = year + "-01-01 00:00:00";
        obj.endtime = year + "-03-31 23:59:59";
      } else if (value == "2") {
        obj.starttime = year + "-04-01 00:00:00";
        obj.endtime = year + "-06-30 23:59:59";
      } else if (value == "3") {
        obj.starttime = year + "-07-01 00:00:00";
        obj.endtime = year + "-09-30 23:59:59";
      } else if (value == "4") {
        obj.starttime = year + "-10-01 00:00:00";
        obj.endtime = year + "-12-31 23:59:59";
      }
      return obj;
    },
    // 改变类型
    changeType(type) {
      if (type == "按季") {
        this.handleChange();
      }
      this.searchObj.TYPE = type;
      this.getData();
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按月: {
          queryType: "month",
          starttime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endtime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          queryType: "year",
          starttime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endtime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    //每月报警预警数
    getData() {
      this.isLoading = true;
      let url =
        Config.dev_url +
        "/api-apps-v2/api/v2/carbon_emissions/kpiranking";
      let data = {
        code: this.currentSelectDeptInfo.CODE,
        // starttime: "2023-01-01 00:00:00",
        // endtime: "2023-12-31 00:00:00"
      };
      if (this.searchObj.TYPE == "按季") {
        Object.assign(data, this.getTimes(this.value));
      } else {
        Object.assign(data, this.transformSearch());
      }
      this.$axios
        .post(url, data)
        .then((res) => {
          let list = [];
          let list1 = [];
          let list2 = [];
          let list3 = [];
          // let list4 = []
          res.data.data.seriesData.forEach((item) => {
            list.push(item.ranking);
            list1.push(item.plan);
            list2.push(item.real);
            list3.push(item.freespace);
          });
          // //console.log(list2);
          // //console.log(list);
          this.detailInfo = {};
          var chart1 = {
            categoryData: res.data.data.categoryData.reverse(),
            seriesData: [
              {
                name: this.LangHandler('actual','实际'),
                value: list2.reverse(),
              },
              {
                name: this.LangHandler('plan','计划'),
                value: list1.reverse(),
              },
            ],
            top: list.reverse(),
            freeSpace: list3.reverse(),
          };
          this.detailInfo.chart1 = chart1;
          this.initEchart();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    initEchart() {
      var { categoryData, seriesData } = this.detailInfo.chart1;
      
      var series = [];
      seriesData.forEach((item) => {
        item.value.forEach(function (items, index) {
          if (items) {
            item.value[index] = items.toFixed(2);
          }
        });
        //console.log(item.value);
        var obj = {
          type: "bar",
          animationDurationUpdate: 2000,
          animationEasingUpdate: "quinticInOut",
          name: item.name,
          data: item.value,
          smooth: false,
          showSymbol: false,
          barGap: 0,
          barWidth: 10,
          emphasis: {
            focus: "series",
          },
        };
        series.push(obj);
      });
      // seriesData.forEach(item => {
      //     var obj = {
      //         type: 'bar',
      //         name: item.name,
      //         data: item.value,
      //         smooth: false,
      //         showSymbol: false,
      //         barGap: 0,
      //         barWidth: 20,
      //     }
      //     series.push(obj)
      // })
      let vm = this;
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "0%",
          right: "6%",
          bottom: "7%",
          top: "15%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            height: 20,
            bottom: 0,
          },
          {
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          formatter: function (params) {
            // //console.log(vm.detailInfo.chart1);
            let str = "";
            str += params[0].axisValueLabel + "<br>";
            str +=
              "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center;margin-top: 10px'>" +
              vm.LangHandler('rank','排名') +
              "<span style='margin-right: 10px'></span>" +
              vm.detailInfo.chart1.top[params[0].dataIndex] +
              "</div>";
            params.forEach((item, index) => {
              str +=
                "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center'>" +
                "<span>" +
                item.marker +
                item.seriesName +
                "</span>" +
                "<span style='margin-right: 10px'></span>" +
                item.value +
                "<span>t</span>" +
                "</div>";
            });
            str +=
              "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center'>" +
              vm.LangHandler('space','剩余空间') +
              "<span style='margin-right: 10px'></span>" +
              vm.detailInfo.chart1.freeSpace[params[0].dataIndex] +
              "<span>%</span>" +
              "</div>";
            return str;
          },
        },
        xAxis: {
          type: "value",
          name: "t",
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
              color: "#1f1f1f",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              width: 0.5,
              color: "#bababa",
            },
          },
          // 控制数据参数最大值和最小值
          // interval: 50,
          // max: 100
        },
        yAxis: {
          type: "category",
          name: this.LangHandler('unit','单位'),
          data: categoryData,
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: "#696969",
            },
          },
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
        },
        // 控住柱状图样式
        series: series,
        color: this.colorList,
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },

  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.carbonBox4 {
  .card-content1 {
    display: flex;
    // /deep/.ant-radio-group {
    //     border: 1px solid #d9d9d9;
    //     border-radius: 4px;
    //     background: #f5f5f5;

    //     .ant-radio-button-wrapper {
    //         background: inherit;
    //         margin: 3px;
    //         border: 0;
    //         height: 26px;
    //         line-height: 26px;

    //         &::before {
    //             background-color: transparent;
    //         }
    //     }

    //     .ant-radio-button-wrapper-checked {
    //         background: #ffffff !important;
    //         border-radius: 2px;
    //         box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);

    //         font-size: 14px;
    //         font-family: ABBvoice_WCNSG_Rg;
    //         font-weight: 400;
    //         text-align: center;
    //         color: #3366ff;
    //         border: 0;

    //         &::before {
    //             background-color: transparent;
    //         }

    //     }
    // }
  }

  .card-content2 {
    flex: 1;
    height: 100%;

    div {
      width: 100%;
      height: 100%;
    }
  }
  /deep/ .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
</style>